import React from 'react'
import { useState } from 'react'
import { useSiteQuery } from '../../hooks/useSiteQuery'
import { useMenuQuery } from '../../hooks/useMenuQuery'
import { ThemeProvider } from 'styled-components'
import { GlobalStyles } from './Layout.styles'
import { theme } from '../ui.conf'
import Header from '../Header'
import Main from '../Main'
import Footer from '../Footer'
import OverlayMenu from '../OverlayMenu'
import Seo from '../Seo'
import './normalize.css'

const Layout = ({ seo, children, aside, isHome, homeDescription, homeTitle, companyTicker }) => {

  const {
    footerContacts,
    footerCopyright,
    footerResponsibility,
    footerWarning,
  } = useSiteQuery().site.mainOptions.optionsPage

  const dataMenu = useMenuQuery().allWpMenu.menu[0].menuItems.items

  const [ menuState, setMenuState ] = useState(false)
  const handleOverlayMenu = () => setMenuState(prev => !prev)

  return (
    <ThemeProvider theme={ theme() }>
      <Seo
        seo={ seo }
        homeDescription={ homeDescription }
        homeTitle={ homeTitle }
        companyTicker={ companyTicker }
      />
      <GlobalStyles />
      <Header
        menu={ dataMenu }
        handleOverlayMenu={ handleOverlayMenu }
      />
      <Main
        aside={ aside }
        isHome={ isHome }>
        { children }
      </Main>
      <Footer
        contacts={ footerContacts }
        copyright={ footerCopyright }
        responsibility={ footerResponsibility }
        warning={ footerWarning }
      />
      <OverlayMenu
        menuState={ menuState }
        menuClose={ handleOverlayMenu }
      />
    </ThemeProvider>
  )
}

export default Layout
