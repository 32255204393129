import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const Menu = ({ menu }) => {

  return (
    <MenuBlock>
      { menu.map(item => (
        !item.parentId ? (
          <li className="Menu-item" key={ item.id }>
            <Link to={ item.url } activeClassName="Menu-item--active">
              { item.label }
            </Link>
            { item.childItems.nodes.length !== 0 ? (
              <ul className="SubMenu">
                { item.childItems.nodes.map(item => (
                  <li className="SubMenu-item" key={ item.id }>
                    <Link to={ item.url } activeClassName="Menu-item--active">
                      { item.label }
                    </Link>
                  </li>
                ))}
              </ul>
            ) : null}
          </li>
        ) : null
      ))}
    </MenuBlock>
  )
}

export default Menu

const MenuBlock = styled.menu `
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1040px) {
    display: none;
  }

  .Menu-item {
    position: relative;
    margin-bottom: 0;
    margin-left: 2rem;
    margin-right: 2rem;
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    a {
      font-family: Montserrat;
      font-size: 1.3rem;
      font-weight: 700;
      line-height: 1;
      letter-spacing: 0;
      color: ${props => props.theme.black};
      text-transform: uppercase;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: ${props => props.theme.blue};
      }
    }

    &:hover {

      .SubMenu {
        display: block;
      }
    }
  }

  .Menu-item--active {
    color: ${props => props.theme.black};
  }

  .SubMenu {
    display: none;
    padding: 1.8rem 2rem;
    position: absolute;
    min-width: 200px;
    width: auto;
    left: -2rem;
    margin: 0;
    top: 4.7rem;
    background: #fff;
    border-radius: 0.4rem;
    border: 1px solid #d6e1e8;
    box-shadow: 0 4px 15px -10px rgba(105, 132, 152, 0.6);
    animation: fadeInMenu 0.3s both ease-in;
  }

  .SubMenu-item {
    a {
      letter-spacing: 0.05em;
    }

    &:first-child {
      margin-top: -0.4rem;
    }
  }

  .SubMenu-item + .SubMenu-item {
    margin-top: 1rem;
  }

  @keyframes fadeInMenu {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
