import React from 'react'
import { Helmet } from 'react-helmet'


export const Seo = ({ seo = false, homeDescription, homeTitle, companyTicker }) => {

  return (
    <Helmet>
      <title>
				{ homeTitle ? `${homeTitle} | Fundtraders` : `${seo.title} | Fundtraders` }
			</title>
      <meta
				name="description"
				content={
					homeDescription
					? `${homeDescription}`
					: `${seo.metaDesc}`
				}
			/>
      <link rel="canonical" href={ seo.canonical } />
    	<meta property="og:locale" content="ru_RU" />
    	<meta property="og:type" content={ seo.opengraphType } />
    	<meta property="og:title" content={ seo.opengraphTitle } />
    	<meta property="og:description" content={ seo.opengraphDescription } />
    	<meta property="og:url" content={ seo.opengraphUrl } />
    	<meta property="og:site_name" content={ seo.opengraphSiteName } />
    	<meta property="article:modified_time" content={ seo.opengraphModifiedTime } />
    	<meta name="twitter:label1" content="Примерное время для чтения" />
    	<meta name="twitter:data1" content={ seo.readingTime } />
    </Helmet>
  )
}

export default Seo
