import React, { useContext } from 'react'
import styled from 'styled-components'
import Hamburger from './Hamburger'
import Menu from './Menu'
import Logo from './Logo'

const Header = ({ dataSite, menu, handleOverlayMenu }) => {

  return (
    <HeaderBlock>
      <div className="Header_wrapper" variant="header">
        <Logo black dataSite={ dataSite } />
        <nav className="Header__nav">
          <Menu menu={ menu } />
        </nav>
        <Hamburger handleOverlayMenu={ handleOverlayMenu } />
      </div>
    </HeaderBlock>
  )
}

export default Header

const HeaderBlock = styled.header `
  position: sticky;
  top: 0;
  display: block;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(7px);
  box-shadow: ${props => props.theme.shadowBlock};
  z-index: 1000;

  @media only screen and (max-width: 480px) {
    position: fixed;
    top: 0;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .Header_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 96%;
    margin-left: auto;
    margin-right: auto;
  }
`
