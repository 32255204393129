import { useStaticQuery, graphql } from "gatsby";

export const useSiteQuery = () => {
  const data = useStaticQuery(graphql`
    query SiteQuery {
      site: wp {
        mainOptions {
          optionsPage {
            footerContacts {
              department
              email
            }
            footerCopyright
            footerResponsibility
            footerWarning
          }
        }
      }
    }
  `)
  return data
}