import React from 'react'
import styled from 'styled-components'

const Main = ({ children, isHome = false }) => {

  return (
    <MainSection isHome={ isHome }>
      { children }
    </MainSection>
  )
}

export default Main

const MainSection = styled.main `
  padding-top: 2rem;
  padding-bottom: 8rem;

  @media only screen and (max-width: 1600px) {
    padding-top: ${props => props.isHome ? '2rem' : '0'};
  }

  @media only screen and (max-width: 480px) {
    padding-bottom: 4rem;
  }
`
