import React from 'react'
import styled from 'styled-components'

const Container = ({ children, header = false, variant }) => {
  return (
    <ContainerBlock
      className={ header ? 'header' : '' }
      variant={ variant } >
      { children }
    </ContainerBlock>
  )
}

export default Container

const ContainerBlock = styled.div `
  display: ${ props => props.variant === 'aside' ? 'flex' : 'block' };
  flex: 1;
  max-width: 1500px;
  width: 96%;
  position: relative;
  margin-top: 0;
  margin-right: auto;
  margin-left: auto;

  @media only screen and (max-width: 480px) {
    flex-direction: ${ props => props.variant === 'aside' ? 'column' : 'row' };
  }

  .Container__columns {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-right: -2rem;
    margin-left: -2rem;

    @media only screen and (max-width: 480px) {
      flex-wrap: ${props => props.variant === 'footer' ? 'wrap' : 'nowrap'};
    }
  }
`
