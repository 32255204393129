import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import logoBlack from '../images/svg-logo-black.svg'
import logoWhite from '../images/svg-logo-white.svg'

const Logo = ({ black, footer = false }) => {

  return (
    <LogoBlock footer={ footer }>
      <Link className="Logo-link" to="/">
        {!black ? (
          <img src={ logoWhite } alt="" />
        ) : (
          <img src={ logoBlack } alt="" />
        )}
      </Link>
    </LogoBlock>
  )
}

export default Logo

const LogoBlock = styled.div `
  width: 80px;
  border-radius: 4px;
  overflow: hidden;

  @media only screen and (max-width: 480px) {
    width: 60px;
    margin-left: ${props => props.footer ? 'auto' : '0'};
    margin-right: ${props => props.footer ? 'auto' : '0'};
  }

  .Logo-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
