import React from 'react'
import styled from 'styled-components'

const Hamburger = ({ handleOverlayMenu }) => {
  return (
    <HamburgerButton onClick={ handleOverlayMenu }>
      <svg className="Hamburger__icon" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M27.5 8.75V6.25H2.5V8.75H27.5ZM27.5 13.75V16.25H2.5V13.75H27.5ZM27.5 21.25V23.75H2.5V21.25H27.5Z" />
      </svg>
    </HamburgerButton>
  )
}

export default Hamburger

const HamburgerButton = styled.div`

  display: none;

  @media only screen and (max-width: 1040px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 100000;
  }

  .Hamburger__icon {
    width: 30px;
    height: 30px;


    path {
      transition: all 0.3s ease;
      fill: ${props => props.theme.black};
    }

    &:hover {
      & path {
        fill: ${props => props.theme.blue};
      }
    }
  }
`
