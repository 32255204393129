export const theme = () => {
  return {
    white: '#FFFFFF',
    black: '#051A2B',
    gray: '#698498',
    grayLight: '#c1d8e9',
    grayExtraLight: '#f0f5f9',
    grayDark: '#3d5568',
    sky: '#288EA5',
    skyLight: '#A9CBE5',
    skyExtraLight: '#F0F7FC',
    blue: '#0DC1E9',
    blueLink: '#0088ff',
    blueGray: '#698498',
    shadowBlock: '0 0 0 1px rgba(167, 181, 191, 0.4)',
    shadowHover: '0 20px 10px 10px rgba(105, 132,152 0.6)',
    borderRadius: '6px',
    transition: 'all 0.2s ease-in'
  }
}