import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`

  #gatsby-focus-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100vh;

    @media only screen and (max-width: 480px) {
      padding-top: 48px;
      overflow-x: hidden;
    }

  }

  html {
    box-sizing: border-box;
    font-family: Montserrat, sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-size: 62.5%; // for use 1rem === 1px

    @media only screen and (max-width: 1280px) {
      font-size: 58.5%;
    }
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.skyExtraLight};
    font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.7;
    letter-spacing: -0.01em;
    color: ${props => props.theme.black};
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    font-weight: 500;
    text-decoration: none;
    outline: 0;
    color: ${props => props.theme.blueLink};
    transition: all 0.2s ease-in;

    &:hover {
      color: ${props => props.theme.blue}
    }
  }

  ul, ol {
    * + li {
      margin-top: 1.2rem;

      @media only screen and (max-width: 480px) {
        margin-top: 0.8rem;
      }
    }
  }

  p, li {
    margin-top: 0;
    margin-bottom: 0;
    word-break: break-word;

    @media only screen and (max-width: 1040px) {
      letter-spacing: 0;
    }

    @media only screen and (max-width: 480px) {
      font-size: 1.5rem;
      line-height: 1.6;
    }
  }

  * + p {
    margin-top: 2.4rem;

    @media only screen and (max-width: 480px) {
      margin-top: 1.8rem;
    }
  }

  h1 {
    font-size: 4.4rem;
    margin: 0 0 4rem 0;
    line-height: 1.1;
    font-family: Montserrat;
    font-weight: 800;
    letter-spacing: -0.01em;

    @media only screen and (max-width: 1480px) {
      font-size: 4rem;
      margin-bottom: 3.4rem;
    }

    @media only screen and (max-width: 1280px) {
      font-size: 3.8rem;
      margin-bottom: 3.2rem;
    }
  }

  h2 {
    font-size: 2.8rem;
    margin: 0 0 3rem 0;
    line-height: 1.1;
    font-family: Montserrat;
    font-weight: 800;
    letter-spacing: 0em;

    @media only screen and (max-width: 1480px) {
      font-size: 2.4rem;
      margin-bottom: 2.2rem;
    }

    @media only screen and (max-width: 1280px) {
      font-size: 2.2rem;
      margin-bottom: 1.8rem;
    }
  }

  * + h2 {
    margin-top: 5rem;

    @media only screen and (max-width: 1480px) {
      margin-top: 4rem;
    }
  }

  h2 + p {
    margin-top: 0;
  }

  h3 {
    font-size: 2.8rem;
    margin: 0 0 2.4rem 0;
    line-height: 1.4;
    font-family: Montserrat;
    font-weight: 800;
    letter-spacing: -0.01em;

    @media only screen and (max-width: 1480px) {
      font-size: 2.4rem;
      margin-bottom: 2rem;
    }

    @media only screen and (max-width: 1280px) {
      font-size: 2rem;
      margin-bottom: 1.6rem;
    }
  }

  * + h3 {
    margin-top: 4.6rem;
  }

  h4 {
    font-size: 2.2rem;
    margin: 0 0 1.8rem 0;
    text-align: left;
    line-height: 1.2;
    font-family: Montserrat;
    font-weight: 800;
    letter-spacing: 0;

    @media only screen and (max-width: 1480px) {
      font-size: 2rem;
      margin-bottom: 1.4rem;
    }

    @media only screen and (max-width: 1280px) {
      font-size: 1.6rem;
      margin-bottom: 1rem;
    }
  }

  * + h4 {
    margin-top: 4rem;
  }

  h4 + p {
    margin-top: 0;
  }

  h5 {
    font-size: 1.9rem;
    margin: 0 0 20px 0;
    text-align: left;
    line-height: 1.2;
    font-family: Montserrat;
    font-weight: 600;
    letter-spacing: 0.02em;

    @media (max-width: 800px) {
      font-size: 1.8rem;
    }
  }

  small {
    display: block;
    margin: 0;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.01em;
    color: ${props => props.theme.blueGray};
  }

  img {
    max-width: 100%;
  }

  menu {
    margin: 0 ;
    padding: 0;
    list-style-type: none;
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #a6bed0;
    margin: 1em 0;
    padding: 0;
  }

  .Btn {
    position: relative;
    padding: 0 3rem;
    height: 4.4rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 0.3rem;
    font-size: 1.6rem;
    line-height: 4.4rem;
    letter-spacing: 0;
    text-decoration: none;
    color: ${props => props.theme.white};
    background-color: ${props => props.theme.blue};
    cursor: pointer;
    transition: .25s linear;

    &:hover {
      color: ${props => props.theme.white};
      background-color: ${props => props.theme.black};
    }

    &.isDisabled {
      border: none;
      color: #9fb6cb;
      pointer-events: none;
      text-decoration: none;
      background-color: #d8e7f3;
    }
  }

  .Btn-zero {
    border: 1px solid ${props => props.theme.black};
    color: ${props => props.theme.black};
    background-color: transparent;

    &:hover {
      color: ${props => props.theme.blue};
      background-color: transparent;
    }
  }

  .Btn-chevron {
    padding: 0 0.7rem;
    height: 3rem;
    border: 1px solid #bfd7e9;
    color: ${props => props.theme.black};
    background-color: transparent;

    &:hover {
      border-color: ${props => props.theme.black};
    }
  }

  .Btn-chevron + .Btn-chevron {
    margin-left: 0.5rem;
  }
`;