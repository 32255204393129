import { useStaticQuery, graphql } from "gatsby"

export const useMenuQuery = () => {
  const data = useStaticQuery(graphql`
    query MenuQuery {
       allWpMenu {
        menu: nodes {
          menuItems {
            items: nodes {
              id
              label
              parentId
              url
              childItems {
                nodes {
                  id
                  label
                  url
                }
              }
            }
          }
        }
      }
    }
  `)
  return data
}