import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { useMenuQuery } from '../hooks/useMenuQuery'
import Container from './Container'
import Logo from './Logo'


const Footer = ({ contacts, copyright, responsibility, warning }) => {

  const { menuItems } = useMenuQuery().allWpMenu.menu[0]

  const menuInvest = menuItems.items.filter(item => {
    return item.label !== 'Обзор компаний' && item.label !== 'Новичкам' && item.label !== 'Инвестиции'
  })

  const menuUseful = menuItems.items.filter(item => {
    return item.label === 'Обзор компаний' || item.label === 'Новичкам'
  })

  return (
    <FooterBlock>
      <Container variant="footer">
        <div className="Container__columns">
          <div className="Footer__column">
            <Logo footer />
            <p className="Footer__column_warning">{ warning }</p>
            <p className="Footer__column_copyright">{ copyright }</p>
          </div>
          <div className="Footer__column">
            <h4 className="Footer__column_title">Инвестиции</h4>
            <ul className="Footer__column_menu">
              { menuInvest.map(item => (
                <li key={ item.id }><Link to={ item.url }>{ item.label }</Link></li>
              ))}
            </ul>
          </div>
          <div className="Footer__column">
            <h4 className="Footer__column_title">Полезное</h4>
            <ul className="Footer__column_menu">
              { menuUseful.map(item => (
                <li key={ item.id }><Link to={ item.url }>{ item.label }</Link></li>
              ))}
            </ul>
          </div>
          <div className="Footer__column">
            <p className="Footer__column_text Footer__column_respons">{ responsibility }</p>
            <ul className="Footer__column_contacts">
              {contacts.map(contact => (
                <li key={ contact.department }>
                  <span>{ contact.department }:</span>
                  <a href={`mailto:${contact.email}`} target="_blank"> { contact.email }</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Container>
    </FooterBlock>
  )
}

export default Footer

const FooterBlock = styled.footer `
  margin-top: auto;
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: ${props => props.theme.black};

  @media only screen and (max-width: 480px) {
    padding-top: 4rem;
    padding-bottom: 4rem;

    * + p {
      margin-top: 1rem;
    }
  }

  .Footer__column {
    flex: 1 1 25%;
    margin-left: 2rem;
    margin-right: 2rem;

    @media only screen and (max-width: 480px) {
      flex: 1 1 100%;
      text-align: center;
    }
  }

  .Footer__column_menu {
    a {
      font-size: 1.4rem;
      color: ${props => props.theme.blueGray};

      &:hover {
        color: ${props => props.theme.white};
      }
    }
  }

  .Footer__column_text,
  .Footer__column_warning {
    font-size: 1.2rem;
    line-height: 1.3;
    letter-spacing: 0.04em;
    color: ${props => props.theme.blueGray};
  }

  .Footer__column_title {
    letter-spacing: 0.01em;
    color: ${props => props.theme.white};

    @media only screen and (max-width: 480px) {
      margin-top: 2.4rem;
      margin-bottom: 0.6rem;
      text-align: center;
    }
  }

  .Footer__column_warning {
    margin-top: 2rem;

    @media only screen and (max-width: 480px) {
      margin-top: 2.2rem;
    }
  }

  .Footer__column_contacts {
    margin-top: 2rem;
    li {
      font-size: 1.2rem;
      line-height: 1.3;
      letter-spacing: 0.04em;
      color: ${props => props.theme.white};
    }

    li + li {
      margin-top: 0.5rem;
    }
  }

  .Footer__column_copyright {
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
    color: ${props => props.theme.white};
  }

  .Footer__column_respons {
    margin-top: 2rem;
  }
`
