import React from 'react'
import styled from 'styled-components'

const SideBarContent = ({ title, children, space = '0px', variant }) => {
  return (
    <SideBarContentItem space={ space } variant={ variant } >
      { title && <h4>{ title }</h4> }
      { children }
    </SideBarContentItem>
  )
}

export default SideBarContent

const SideBarContentItem = styled.div `
  width: 100%;
  padding: 2.2rem;
  position: ${props => props.variant === 'sticky' ? 'sticky' : 'static'};
  top: ${props => props.variant === 'sticky' ? '87px' : 'auto'};
  margin-top: ${props => props.space};
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props => props.theme.white};
  box-shadow: ${props => props.theme.shadowBlock};

  @media only screen and (max-width: 1280px) {
    top: ${props => props.variant === 'sticky' ? '82px' : 'auto'};
  }

  @media only screen and (max-width: 780px) {
    padding: 2rem;
  }

  h3 {
    margin-bottom: 3rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid ${props => props.theme.grayLight};

    @media only screen and (max-width: 1600px) {
      margin-bottom: 1.4rem;
    }
  }

  h4 {
    margin-bottom: 3rem;
    padding-bottom: 2rem;
    /* border-bottom: 1px solid ${props => props.theme.grayLight}; */

    @media only screen and (max-width: 1600px) {
      margin-bottom: 1.4rem;
    }
  }
`
