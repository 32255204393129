import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { useMenuQuery } from '../hooks/useMenuQuery'

const OverlayMenu = ({ menuState, menuClose }) => {

  const dataMenu = useMenuQuery().allWpMenu.menu[0].menuItems.items

  return (
    <OverlayMenuBlock menuState={ menuState }>
      <h3 className="OverlayMenu__title">Навигация</h3>
      <ul className="OverlayMenu__links">
        {dataMenu.map(item => (
          <li className="OverlayMenu__item"  key={ item.id }>
            <Link activeClassName="OverlayMenu__item--active" to={ item.url }>
              { item.label }
            </Link>
          </li>
        ))}
      </ul>
      <div
        className="OverlayMenu__close"
        onClick={ menuClose }
        onKeyDown={ menuClose }
        role="button"
        tabIndex="0"
      >
        <svg className="OverlayMenu__icon" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M19.9807 18.2131L27.9356 26.168L26.1678 27.9358L18.2129 19.9808L10.2579 27.9358L8.49017 26.168L16.4451 18.2131L8.49017 10.2581L10.2579 8.49035L18.2129 16.4453L26.1678 8.49035L27.9356 10.2581L19.9807 18.2131Z" />
        </svg>
      </div>
    </OverlayMenuBlock>
  )
}

export default OverlayMenu

const OverlayMenuBlock = styled.div `
  position: fixed;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: ${props => props.menuState ? '1' : '0'};
  transform: ${props => props.menuState ? 'translateX(0%)' : 'translateX(-100%)'};
  z-index: 100000;
  background: #fff;
  padding: 3rem;
  transition: all 0.3s ease;

  .OverlayMenu__title {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid ${props => props.theme.grayLight};
  }

  .OverlayMenu__close {
    position: fixed;
    top: 2rem;
    right: 2rem;
    width: 32px;
    height: 32px;
    cursor: pointer;
    transition: all 1s ease-in-out;
    outline: none;
    transition: all 0.3s ease;

    :hover {
      transform: rotate(180deg);
    }
  }

  .OverlayMenu__icon {
    path {
      transition: all 0.3s ease;
      fill: ${props => props.theme.black};
    }
  }

  .OverlayMenu__item {

    a {
      font-size: 1.6rem;
      font-weight: 500;
      color: ${props => props.theme.black};
    }
  }

  .OverlayMenu__item + .OverlayMenu__item {
    margin-top: 1.2rem;
  }
`
