import React from 'react'
import styled from 'styled-components'

const SideBar = ({ children, page }) => {
  return (
    <SideBarBlock page={ page }>
      { children }
    </SideBarBlock>
  )
}

export default SideBar

const SideBarBlock = styled.div `
  flex: 0 0 15%;
  min-width: 320px;

  @media only screen and (max-width: 780px) {
    min-width: 260px;
  }

  @media only screen and (max-width: 480px) {
    margin-top: ${props => props.page !== 'company' ? '2rem' : '0'};
    position: static;
    min-width: none;
    width: 100%;
  }
`
