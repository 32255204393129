import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Img = ({ localFile, styles, alt }) => {

  const img = getImage(localFile)

  return (
    <>
      <GatsbyImage
        className={ styles ? styles : null }
        image={ img }
        alt={ alt }
        title={ alt }
        loading="lazy"
      />
    </>
  )
}

export default Img
